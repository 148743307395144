import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SCLandingWrapper from '../components/SCLandingWrapper';
import ConnectElectric from '../components/ConnectElectric';

function ElectricRatesPage({ siteConfig }) {
  return siteConfig.id === 'mi' ? (
    <ConnectElectric />
  ) : (
    <div style={{ marginTop: '20px' }}>
      <SCLandingWrapper headerIfName />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ElectricRatesPage);
